import React, { useContext } from 'react';
import reduce from 'lodash/reduce';
import styled from '@emotion/styled';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { Link } from 'gatsby';
import { navigate } from 'gatsby-link';

import StoreContext from '../context/StoreContext';
import ThemeContext from '../context/ThemeContext';

import ButtonOutline from './ButtonOutline';

import SvgCart from '../icons/Cart';

const useQuantity = () => {
  const {
    store: { checkout },
  } = useContext(StoreContext);
  const items = checkout ? checkout.lineItems : [];
  const total = reduce(items, (acc, item) => acc + item.quantity, 0);
  return [total !== 0, total];
};

const ShopbarStyles = styled.nav(
  (props) => `
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
  justify-content: flex-start;
  padding: ${props.theme.size.base * 13}px ${props.theme.size.base * 4}px ${
    props.theme.size.base * 4
  }px;
  ${
    (props.breakpoints.lg || props.breakpoints.xl) &&
    `
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  `
  };

  .cart-button {
    white-space: nowrap;
  }

  .breadcrumb {
    width: 100%;
    margin-top: ${props.theme.size.base * 4}px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: ${props.theme.size.fontSize[5]};
    line-height: ${props.theme.size.lineHeight[5]};
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    .seperater {
      font-weight: 300;
      padding: 0 ${props.theme.size.base * 1}px;
      color: ${props.theme.color.foreground[10]};
      font-size: ${props.theme.size.fontSize[2]};
      line-height: ${props.theme.size.lineHeight[5]};
    }
    a {
      color: ${
        props.theme.dark
          ? props.theme.color.accent[60]
          : props.theme.color.accent[40]
      };
      text-decoration: none;
      transition: color 450ms ease;
      &:hover {
        color: ${
          props.theme.dark
            ? props.theme.color.primary[60]
            : props.theme.color.primary[40]
        };
      }
    }
    ${
      (props.breakpoints.lg || props.breakpoints.xl) &&
      `
      margin-top: 0;
      margin-right: ${props.theme.size.base * 1}px;
    `
    };
   }
  `
);

const Shopbar = ({ location, productTitle }, props) => {
  const [hasItems, quantity] = useQuantity();
  const breakpoints = useBreakpoint();

  return (
    <ThemeContext.Consumer>
      {(theme) => (
        <ShopbarStyles theme={theme} breakpoints={breakpoints}>
          <div className='breadcrumb'>
            {location.pathname === '/shop' && <div>Shop</div>}
            {location.pathname === '/cart' && (
              <>
                <Link to='/shop'>Shop</Link>
                <div className='seperater'>/</div>
                <div>Cart</div>
              </>
            )}
            {location.pathname.split('/')[2] && (
              <>
                <Link to='/shop'>Shop</Link>
                <div className='seperater'>/</div>
              </>
            )}
            {productTitle && location.pathname.split('/')[1] === 'product' && (
              <div>{productTitle}</div>
            )}
          </div>

          <ButtonOutline
            label='Go to Shopping Cart'
            size={-1}
            className='cart-button'
            onClick={() => {
              navigate(`/cart`);
            }}
          >
            <SvgCart></SvgCart> {hasItems && <span>{quantity}</span>}
          </ButtonOutline>
        </ShopbarStyles>
      )}
    </ThemeContext.Consumer>
  );
};

export default Shopbar;
